import React, { Component } from 'react';

export class Signatures extends Component {
 
    constructor(props) {
        super(props);
        this.state = { signs: [], loading: true };
    }
    componentDidMount() {
        this.GetSigns();
    }
    static RenderCompanies(forecasts) {
        return (
            <table className='table sign' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Logo</th>
                        <th>Name</th>
                        <th>Organisation</th>

                    </tr>
                </thead>
                <tbody>
                    {forecasts.map((co,i) =>
                        <tr key={i}>
                            <td>{co.company}</td>
                            <td>
                                {co.logoNameKey != "" && 
                                <img src={co.logoPathKey} alt={'Logo ' + co.company }   />
                                }

                            </td>
                            <td>{co.name}</td>
                            <td>{co.organisation}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Signatures.RenderCompanies(this.state.signs);

        return (
            <div style={{ marginTop: '25px' }}>
                <h3 id="tabelLabel" >Signatures ({this.state.signs.length})</h3>
                {contents}
            </div>
        );
    }

    async GetSigns() {
        const response = await fetch('/api/post/Signatures?' + new Date());
        const data = await response.json();
        this.setState({ signs: data, loading: false });
    }
}
