import React, { Component } from 'react';
import { Form, FormGroup, Label, Col, Input, Button, UncontrolledPopover, CardBody, Card, CardTitle } from 'reactstrap';


class LogoForm extends Component {
    constructor(props) {
        super(props);
        this.onsubmit = this.onsubmit.bind(this);
    }



    async onsubmit(e) {
        e.preventDefault();
        const data = new FormData();
        if (document.getElementById("logo").files.lenght > 0 != null) {
            data.append('logo', document.getElementById("logo").files[0]);
        }
        data.append('company', document.getElementById("Company").value);
        data.append('name', document.getElementById("name").value);
        data.append('email', document.getElementById("email").value);
        data.append('companyEmail', document.getElementById("companyEmail").value);
        data.append('organisation', document.getElementById("organisation").value);
        try {

            let ret = await fetch("/api/Post/upload", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: data
            });

            if (ret.status == 200) {
                var res = await ret.json();
                alert('Thanks!')
                window.location = "/";

            }
            else
                alert(await ret.text())
        }
        catch (ex) {
            console.log(ex)
            //   alert(await ex.text());
        }


    }

    render() {
        return (
            <div style={{ marginTop: '25px' }} className="d-print-none" >
                <Card body>
                    <CardTitle tag="h3">

                        Sign the Letter</CardTitle>
                    <CardBody>
                        <Form onSubmit={(e) => this.onsubmit(e)}>
                            <FormGroup row>
                                <Label
                                    for="Company"
                                    sm={2}
                                >
                                    Company
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="Company"
                                        name="Company name"
                                        placeholder="Company"
                                        type="text" required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="companyEmail"
                                    sm={2}
                                >
                                    Company email
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="companyEmail"
                                        name="companyEmail"
                                        placeholder="company@email.com"
                                        type="email" required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="logo"
                                    sm={2}
                                >
                                    Company logo
                                </Label>
                                <Col sm={8}>
                                    <Input
                                        id="logo"
                                        name="logo"
                                        placeholder="Logo  png/jpg/gif"
                                        type="file"
                                    />
                                </Col>
                                <Col sm={2}>
                                    .png, .gif, .jpg max 1MB
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label
                                    for="name"
                                    sm={2}
                                >
                                    Your name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Your name"
                                        type="text" required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="email"
                                    sm={2}
                                >
                                    Email
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="email"
                                        name="email"
                                        placeholder="your@email.com"
                                        type="email" required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="organisation"
                                    sm={2}
                                >
                                    Organisation                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="organisation"
                                        name="organisation"
                                        type="select"
                                    >
                                        <option>Not set
                                        </option>
                                        <option>Association of Consulting Architects in Norway
                                        </option>
                                        <option>Danish Association of Architectural firms
                                        </option>
                                        <option>The Association of Finnish Architects  Offices
                                        </option>
                                        <option>The Association of Architectural Firms in Iceland
                                        </option>
                                            <option>Innovationsföretagen in Sweden</option>
                                        <option>International company(not connected to one of the Nordic associations)</option>
                                        <option>Consultant</option>
                                        <option>Developer</option>
                                        <option>Contractor</option>
                                        <option>Other</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                           

                            <FormGroup row>
                                <Label
                                    for=""
                                    sm={2}
                                >

                                </Label>
                                <Col sm={10}>
                                    <Button type="submit">Sign the letter</Button>
                                </Col>


                            </FormGroup>

                        </Form>
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export default LogoForm;
