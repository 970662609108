import sanityClient  from  '@sanity/client';

export const sanityConfig = {
    projectId: '1dow5uc0',
    dataset: 'production',
    apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
    token: '', // or leave blank for unauthenticated usage
    useCdn: false, // `false` if you want to ensure fresh data
}

export const client = sanityClient(sanityConfig);