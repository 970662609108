import React, { Component } from 'react';

import { client, sanityConfig } from "./sanity"

import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import { Form, FormGroup, Label, Col, Input, Collapse, Container, Row, Card, Button, Modal, ModalBody, ModalHeader, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import LogoForm from './LogoForm';
import { Signatures } from './Signatures';
import { AiFillPrinter } from 'react-icons/ai'

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source)
}
const serializers = {
    types: {
        code: props => (
            <pre data-language={props.node.language}>
                <code>{props.node.code}</code>
            </pre>
        )
    },
    marks: {
        internalLink: ({ mark, children }) => {
            const { slug = {} } = mark
            const href = `/${slug.current}`
            return <a href={href}>{children}</a>
        },
        link: ({ mark, children }) => {
            console.log("mark" + mark)
            console.log(mark)
            const { href } = mark
            return <a href={href} target="_blank" rel="noopener">{children}</a>
        }
    }
}



export class Home extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            ar: { title: "", files: [], extraContents: [] }, col: false
        }
        this.openContent = this.openContent.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMenmu = this.toggleMenmu.bind(this);

    }

    componentDidMount() {
        const query = '*[_type == "contentArticle" && slug.current == "open-letter-to-autodesk"] {..., "files": files[]->{...,"file":  file.asset->{...}, "extraContents" : extraContents->{...}  }}'
        const params = { minSeats: 2 }
        client.fetch(query, params).then((arts) => {
            arts.forEach((art) => {
                this.setState({ ar: art })
            })
        })
    }
    openContent(i) {
        var s = this.state;
        s.ar.extraContents[i] = { ...s.ar.extraContents[i], open: true };
        this.setState({ ar: s.ar })
    }
    toggle(i) {
        var s = this.state;
        s.ar.extraContents[i].open = !s.ar.extraContents[i].open;
        this.setState({ ar: s.ar })
    }
    toggleMenmu(i) {

        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        return (
            <div>
                <Navbar container="md" fixed='TOP' expand="md"  style={ {  borderBottom :  '1px solid #222'}} >
                <NavbarBrand href="/">Home</NavbarBrand>

                    <NavbarToggler onClick={this.toggleMenmu} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="me-auto" navbar>
                         
                            {this.state.ar.extraContents.map((h, i) =>
                                <NavItem>
                                    <NavLink onClick={() => this.openContent(i)} href="#" >{h.title}</NavLink>
                                    <Modal isOpen={h.open} toggle={() => this.toggle(i)} size="lg" >
                                        <ModalHeader>{h.title}</ModalHeader>
                                        <ModalBody>

                                            <BlockContent blocks={h.content} serializers={serializers} projectId={sanityConfig.projectId} dataset={sanityConfig.dataset} />
                                        </ModalBody>
                                    </Modal>
                                </NavItem>
                            )}
                               <NavItem>
                                <NavLink onClick={() => window.print()} href="#"   className=" printNone"> <AiFillPrinter></AiFillPrinter> Print letter</NavLink>
                            </NavItem>

                        </Nav>
                    </Collapse>
                </Navbar>
                <Row>
                    <Col xs={8} >


                    </Col>
                    <Col>
                    </Col>
                </Row>

                <Row>

                    <Col xs={8}  >
                        <h1 style={{ marginTop: '25px' }}>{this.state.ar.title}</h1>
                        <p>
                        </p>
                        <BlockContent blocks={this.state.ar.intro} serializers={serializers} projectId={sanityConfig.projectId} dataset={sanityConfig.dataset} />
                        {this.state.col === false &&
                            <p className="printNone">                            <a href="#" onClick={() => { this.setState({ col: !this.state.col }) }} >Read more</a></p>}

                        <Collapse isOpen={this.state.col}>
                            <BlockContent blocks={this.state.ar.content} serializers={serializers} projectId={sanityConfig.projectId} dataset={sanityConfig.dataset} />
                            <p className="printNone">                            <a href="#" onClick={() => { this.setState({ col: !this.state.col }) }} >Read less</a>
                            </p>
                        </Collapse>
                        <Row className="printNone" style={{ paddingTop: '15px' }} >
                            <Col xs={8}>  <p>
                                <a onClick={() => window.print()} href="#" color="link" style={{ marginTop: '25px' }} className="printNone">Print letter</a>
                            </p></Col>
                            <Col><a className="float-end" href="mailto:post@the-nordic-letter.com">post@the-nordic-letter.com</a></Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className="float-end" style={{ marginTop: '25px' }}>
                            {this.state.ar.files.map(f =>
                                <div style={{ marginBottom: '25px', }}>
                                    <img src={f.file.url} style={{ width: '150px' }} />
                                </div>
                            )}
                        </div></Col>
                </Row>
                <Row>
                    <Col>



                    </Col>
                </Row>
                <Row className='pageBreak'>
                    <Col>
                        <LogoForm></LogoForm>
                        <Signatures />
                    </Col>
                </Row>

            </div>
        );
    }
}